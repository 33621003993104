<template>
  <section id="hero">
    <v-parallax
      :min-height="minHeight"
      :max-height="maxHeight"
      :src="require('@/assets/bg.jpg')"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="700"
          width="100%"
        >
          <!-- <v-alert v-if="$route.name === 'home'" type="warning" class="op-50" text outlined dense>
            This app is in development - please share feedback and issues in our
            <a href="https://discord.gg/WZ7EgH7w4d" target="_blank">
              Community Discord</a
            >
          </v-alert> -->
          <!-- HOME messaging -->
          <div v-if="$route.name === 'home'">
            <base-subheading title="Enter party name, join your private Discord." />
          </div>

          <!-- INVITE messaging -->
          <div v-if="$route.name !== 'home'">
            <base-subheading
              v-show="valid && isGettingInvite"
              :title="`Preparing to join '${partyName}'`"
            />
            <!-- <base-subheading
              v-show="!isGettingInvite"
              :title="`READY to join party: '${partyName}'`"
            /> -->
            <h2 id="msg-ready" v-show="!isGettingInvite">Party:
              <strong>{{ partyName }}</strong>
            </h2>
            <base-subheading
              v-show="!valid"
              class="red--text"
              title="Invalid party link..."
            />
          </div>
          <p 
            v-show="$route.name == 'party' && shareLink && valid"
            id="msg-instruction"
            >
            Your party can <em>enter <strong>{{ partyName }}</strong> on instaparty.gg</em>, <span>or use either link below:</span>
          </p>
          <v-responsive
            max-width="600"
            width="100%"
            class="text-h2"
            transition="scale-transition"
          >
            <v-text-field
              v-show="$route.name == 'party' && shareLink && valid"
              id="link-partyup"
              ref="shareLinkInput"
              v-model="shareLink"
              class="mt-3"
              label="Share Link ( Neat! )"
              outlined
              dense
              readonly
              hint="Link Copied!"
              @click="selectInput($event)"
            />

            <v-text-field
              v-show="$route.name == 'party' && discordLink && valid"
              id="link-discord"
              ref="discordLinkInput"
              v-model="discordLink"
              class="mt-3"
              label="Discord Invite Link ( Gross! )"
              outlined
              dense
              readonly
              hint="Invite Link Copied!"
              @click="selectInput($event)"
            />

            <v-form
              id="party-form"
              ref="form"
              v-model="valid"
              lazy-validation
              @submit="submit"
              :class="{'text-right': $route.name == 'party'}"
            >
              <v-text-field
                v-show="$route.name == 'home'"
                id="party-input"
                ref="partyInput"
                v-model="partyName"
                class="text-h5"
                height="40"
                :counter="15"
                :rules="nameRules"
                label="Party Name"
                required
              />
              <base-btn
                v-show="$route.name == 'home'"
                :disabled="!valid"
                class="ml-n1"
                height="50"
                @click="submit"
              >
                Continue
              </base-btn>

              <base-btn
                id="btn-join"
                v-show="$route.name == 'party' && valid"
                block
                :class="{ active: !isGettingInvite }"
                :disabled="isGettingInvite"
                :outlined="isGettingInvite"
                :height="isGettingInvite ? '40' : '60'"
                :loading="isGettingInvite"
                @click="partyUp()"
              >
                <v-icon x-large class="mr-2">{{ discordIcon }}</v-icon> Join <strong>{{ partyName }}</strong>
              </base-btn>

              <base-btn
                v-show="$route.name == 'party'"
                outlined
                text
                x-large
                height="50"
                color="secondary"
                @click="newParty"
              >
                Create New party
              </base-btn>
            </v-form>
          </v-responsive>

          <!-- <base-body>
            If it exists, you can join it, otherwise you'll create it
          </base-body> -->

          <div
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'flex-column align-start'
                : 'align-center'
            "
            class="d-flex flex-wrap"
          >
            <!-- <base-btn>
              Discover More
            </base-btn> -->

            <!-- <span class="font-weight-bold ml-6 mr-4 my-4">or</span>

            <base-btn
              :ripple="false"
              class="pa-1"
              height="auto"
              text
            >
              Get Started Now
            </base-btn> -->
          </div>
        </v-responsive>
      </v-container>
    </v-parallax>
  </section>
</template>

<script>
import { mdiDiscord } from '@mdi/js';
import axios from 'axios';
export default {
  name: "SectionHero",

  provide: {
    theme: { isDark: true },
  },
  data: () => ({
    isGettingInvite: true,
    discordIcon: mdiDiscord,
    discordLink: "",
    partyName: "",
    shareLink: "",
    inviteCode: "",
    joinCtaText: "Enter party name, join your private Discord.",
    valid: false,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required!",
      (v) => (v && v.length <= 15) || "Name must be less than 15 characters!",
      (v) =>
        /^[A-Za-z0-9\-_]+$/.test(v) ||
        "No symbols or spaces allowed (a-z 0-9 - _)",
      (v) => !/^[-_]/.test(v) || "Can't start name with a separator.",
      (v) => !/[-_]$/.test(v) || "Can't end name with a separator.",
    ],
    select: null,
  }),

  computed: {
    maxHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "50vh" : "50vh";

      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    },
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "20vh" : "20vh";

      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    },
  },

  watch: {
    $route: function () {
      this.updatePageState();
    },
  },
  mounted() {
    // if deeplinked
    if (this.$route.name === "party") {
      const routeId = this.$route.params.id;
      this.partyName = routeId;
    }
    this.updatePageState();
  },
  methods: {
    newParty: function () {
      this.partyName = "";
      this.$router.push("/");
    },
    updatePageState: function () {
      const partyInput = this.$refs.partyInput;
      const routeId = this.$route.params.id;

      if (routeId?.length > 0) {

        const postData = { partyId: routeId };
        axios.post("https://j9tbf657le.execute-api.us-east-1.amazonaws.com/party", postData)
        .then(response => {
          this.inviteCode = response.data.inviteId;
          this.discordLink = "https://discord.gg/"+this.inviteCode;
          this.isGettingInvite = false;
        })
        .catch(error => {
          console.error(error);
        });
        
        

        // invite page
        this.partyName = routeId;
        this.$refs.form.validate();
        this.shareLink = window.location.href;
      } else {
        // home page
        // reset
        this.partyName = "";
        this.shareLink = "";
        this.discordLink = "";
        this.inviteCode = "";
        this.isGettingInvite = "";
        // focus input
        this.joinCtaText = "Enter party name, join your private Discord.";
        setTimeout(() => {
          partyInput.focus();
        }, 750);
      }
    },
    submit: function (e) {
      if (e) e.preventDefault();
      if (this.$refs.form.validate()) {
        // window.open(this.inviteId)
        this.$router.push({ name: "party", params: { id: this.partyName } });
      }
    },
    partyUp: function (e) {
      window.open(this.discordLink);
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    selectInput(e) {
      e.target.setSelectionRange(0, e.target.value.length);
      document.execCommand("copy");
    },
  },
};
</script>

<style>
#party-form .v-input input {
  max-height: 132px !important;
}
#btn-join {
  transition: all 0.5s, 0.5s;
  
}

#btn-join span {
  font-weight: 300;
  text-transform: none;
  font-size: 0.75rem;
}

#btn-join strong {
  font-size: 1.25rem;
  padding-left: 5px;
  font-weight: 700;
  text-transform: none;
}

#msg-ready {
  font-weight: 300;
  font-size: 2.25rem;
}

#msg-ready strong {
  font-weight: 800;
  /* font-size: 2.75rem; */
  background: rgba(0,0,0,0.25);
  padding: 0rem 0.75rem 0.5rem;
  border-radius: 1.25rem;
}

#msg-instruction {
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

#msg-instruction span{
  color: #cccccc;
}

.v-input input#link-partyup {
  font-size: 1.1rem;
}

.v-input input#link-discord {
  color: #cccccc;
  font-size: 0.85rem;
}

</style>
